import type { BillableHoursCountQuery, DynamicMetricsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import { useContext, useState } from "react";
import { Chart } from "react-google-charts";
import ButtonCloseModal from "~/components/button-close-modal";
import Link from "~/components/link";
import Modal from "~/components/modal";
import NumberedTable from "~/components/numbered-table";
import Panel from "~/components/panel";
import { ScoreboardUserContext } from "~/contexts";
import {
  dayIsBefore,
  dayIsSameOrBefore,
  endOfMonth,
  endOfQuarter,
  formatDate,
  parseDate
} from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";

interface Props {
  quarter: string;
  height?: string;
  employee?: BillableHoursCountQuery["employee"];
}

export default function BillableHours({ height, quarter, employee }: Props) {
  const { dynamicMetrics } = useLoaderData<{
    dynamicMetrics: DynamicMetricsQuery["dynamicMetrics"];
  }>();
  const goals = dynamicMetrics.filter((m) => m.name === "Billable Hours: Goal");
  const targets = dynamicMetrics.filter(
    (m) => m.name === "Billable Hours: Target"
  );
  const actuals = dynamicMetrics.filter((m) => m.name === "Billable Hours");
  const user = useContext(ScoreboardUserContext);
  const monthEnd = endOfMonth(new Date());
  const quarterEnd = endOfQuarter(parseDate(quarter));
  const cutoff = dayIsBefore(monthEnd, quarterEnd) ? monthEnd : quarterEnd;
  const [showGoal, setShowGoal] = useState(false);

  const items: (string | number | { role: string })[][] = [
    [
      "Label",
      "Hours",
      { role: "annotation" },
      { role: "style" },
      "Target",
      { role: "annotation" },
      { role: "style" },
      "Goal",
      { role: "annotation" },
      { role: "style" }
    ]
  ];
  _.sortBy(goals, "date").forEach((goal) => {
    const item = actuals.find((m) => m.date === goal.date);
    const target = targets.find((g) => g.date === goal.date);
    const currentValue = item ? parseFloat(item.sum!) : 0;
    const currentTarget = target ? parseFloat(target.sum!) : 0;
    const currentGoal = parseFloat(goal.sum!);

    const currentColor =
      currentValue < currentTarget * 0.9 ? "#d9534f" : "#5cb85c";
    items.push([
      formatDate(goal.date, { format: "MMM YYYY" }),
      currentValue,
      item
        ? `Actual: ${formatNumber(currentValue, { zeroes: true })}`
        : dayIsBefore(new Date(), goal.date!)
          ? "Not Yet Started"
          : "Actual: 0.0",
      currentColor,
      currentTarget,
      `Target: ${formatNumber(currentTarget, { zeroes: true })}`,
      "#f0ad4e",
      currentGoal,
      `Goal: ${formatNumber(currentGoal, { zeroes: true })}`,
      "#337ab7"
    ]);
  });
  const billableGoals = _.orderBy(
    (employee?.billableGoals || []).filter((g) =>
      dayIsSameOrBefore(g.effectiveDate, cutoff)
    ),
    "effectiveDate",
    "desc"
  );

  const billableGoal = billableGoals.length ? billableGoals[0] : undefined;
  const usedLeave = _.sumBy(
    (employee?.user?.timesheets || []).filter(
      (t) =>
        t.project.number === "OH-051" &&
        t.deliverable &&
        t.deliverable.description !== "Holidays"
    ),
    (t) => parseFloat(t.hours)
  );
  let workingHours = employee?.schedule?.startsWith("Half-Day Fridays") ? 9 : 8;
  // Half goal on Fridays
  if (new Date().getDay() === 5 && "Half-Day Fridays" === employee?.schedule) {
    workingHours = 4;
  }
  let max = workingHours - usedLeave;
  if (max < 0) max = 0;
  const dayGoal = billableGoal
    ? max * (parseFloat(billableGoal.percentage || "0") / 100)
    : 0;

  const formattedGoal = formatNumber(
    parseFloat(billableGoal?.percentage || "0") / 100,
    {
      format: "0.0%"
    }
  );

  const maxValue =
    _.sortBy(items.slice(1).flatMap((i) => [i[1], i[4], i[7]])).at(-1) || 0;

  return (
    <div>
      <Panel className="mb-0">
        <Panel.Header title="Billable Hours" className="text-center" />

        <Chart
          height={height || (user ? "251px" : "374px")}
          width="auto"
          chartType="BarChart"
          data={items}
          options={{
            chartArea: {
              top: 15,
              right: 15,
              bottom: 15,
              left: 70,
              height: "100%",
              width: "100%"
            },
            bar: {
              groupWidth: "95%"
            },
            hAxis: {
              minValue: 0,
              viewWindow: {
                min: 0,
                max: maxValue
              },
              ticks: [],
              textPosition: "none"
            },
            legend: "none"
          }}
        />
        {user && employee && (
          <Panel.Footer className="text-center">
            {billableGoal && (
              <>
                <Link to={() => setShowGoal(true)}>
                  Today's Goal: {formatNumber(dayGoal)}
                </Link>
                {/* <span>
                  Average Daily Goal:{" "}
                  {formatNumber(
                    (parseFloat(billableGoal.percentage || "0") / 100) * 8
                  )}{" "}
                  Hours
                </span> */}
              </>
            )}
          </Panel.Footer>
        )}
      </Panel>
      {showGoal && (
        <Modal onClose={() => setShowGoal(false)}>
          <Modal.Header title="Goal Explanation" />
          <NumberedTable>
            <tbody>
              <tr>
                <td />
                <td>Working Hours</td>
                <td className="text-right">{formatNumber(workingHours)}</td>
              </tr>
              {usedLeave > 0 && (
                <tr>
                  <td />
                  <td>Time Off</td>
                  <td className="text-right">({formatNumber(usedLeave)})</td>
                </tr>
              )}
              <tr>
                <td />
                <td>Available Working Hours</td>
                <td className="text-right">{formatNumber(max)}</td>
              </tr>
              <tr>
                <td />
                <td>Billable Goal Percentage</td>
                <td className="text-right">{formattedGoal}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="success font-bold">
                <td />
                <td>
                  {formattedGoal} * {formatNumber(max)} Hours
                </td>
                <td className="text-right">{formatNumber(dayGoal)}</td>
              </tr>
            </tfoot>
          </NumberedTable>
          <Modal.Footer>
            <ButtonCloseModal />
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
